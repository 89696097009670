.details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.detail {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  .label {
    display: inline-block;
    flex-shrink: 0;
    width: 144px;

    &.DE {
      width: 110px;
    }
  }
}
